import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Terminal.css';
import { debounce } from 'lodash';
import MobileUI from './MobileUI';
import ReactTypingEffect from 'react-typing-effect';

const fileSystem = {
  '/': {
    about: { type: 'directory', content: {} },
    skills: { type: 'directory', content: {} },
    projects: { type: 'directory', content: {} },
    contact: { type: 'directory', content: {} },
  },
};

fileSystem['/'].about.content = {
  'summary.txt': { type: 'file', content: "Todd Watts - Full Stack Developer with 10 years of experience in web development. Transitioned from marketing to tech, leveraging business acumen to deliver effective solutions." },
  'objective.txt': { type: 'file', content: "Seeking a challenging role to further apply my programming skills and business experience in web development." },
  'contact.txt': { type: 'file', content: "13630 Key Lime Blvd, West Palm Beach, FL 33412\nPhone: 561-313-7056\nEmail: todd@toddwatts.com" },
  'experience.txt': { type: 'file', content: "Full Stack Developer (2020 - Present)\nEngineered Tax Services, West Palm Beach, FL\n• Transitioned from marketing to full-time developer\n• Developed WordPress, SharePoint, and Azure Cloud projects\n• Implemented responsive web designs and improved user experiences\n• Utilized React, Node.js, and TypeScript for modern web applications\n\nVice President of Marketing (Mar 2018 - Aug 2020)\nEngineered Tax Services, West Palm Beach, FL\n• Managed B2B and B2C branding and marketing analytics\n• Developed and executed digital marketing strategies\n• Increased online presence and lead generation through SEO and content marketing\n\nFreelance Internet Marketer (Jul 2015 - Feb 2018)\nSelf-Employed, West Palm Beach, FL\n• Drove targeted traffic and converted visitors into leads\n• Designed and implemented marketing campaigns for various clients\n• Utilized data analytics to optimize marketing strategies" },
};

fileSystem['/'].skills.content = {
  'technical.txt': { type: 'file', content: "• Frontend: HTML5, CSS3, JavaScript (ES6+), React, TypeScript, Redux\n• Backend: Node.js, Express.js, Python, PHP\n• Databases: SQL (MySQL, PostgreSQL), NoSQL (MongoDB)\n• Cloud: Azure, AWS, Google Cloud Platform\n• CMS: WordPress, SharePoint, Headless CMS platforms\n• Version Control: Git, GitHub\n• API Development and Integration\n• Responsive Web Design\n• Progressive Web Apps (PWAs)\n• Web Performance Optimization" },
  'software.txt': { type: 'file', content: "• Adobe Creative Suite: Photoshop, Illustrator, After Effects, InDesign\n• Video Editing: Adobe Premiere Pro, Final Cut Pro\n• Project Management: Jira, Trello, Asana\n• Design: Figma, Sketch\n• Collaboration: Slack, Microsoft Teams\n• Analytics: Google Analytics, SEMrush, Ahrefs" },
  'marketing.txt': { type: 'file', content: "• Search Engine Optimization (SEO)\n• Content Creation and Strategy\n• Content Marketing\n• Social Media Marketing\n• Email Marketing\n• Pay-Per-Click (PPC) Advertising\n• Conversion Rate Optimization (CRO)\n• Marketing Analytics and Reporting\n• Brand Development and Management\n• Marketing Automation" },
};

fileSystem['/'].projects.content = {
  'portfolio.txt': { type: 'file', content: "1. Responsive E-commerce Platform\n   • Developed a full-stack e-commerce solution using React, Node.js, and MongoDB\n   • Implemented secure payment gateway integration and user authentication\n\n2. Custom WordPress Theme and Plugins\n   • Created a bespoke WordPress theme for a client in the finance sector\n   • Developed custom plugins to extend WordPress functionality\n\n3. Azure-based Enterprise Application\n   • Led the development of a cloud-based application using Azure services\n   • Implemented microservices architecture and containerization with Docker\n\n4. Marketing Analytics Dashboard\n   • Built a real-time analytics dashboard using React and D3.js\n   • Integrated various APIs to aggregate and visualize marketing data\n\n5. Progressive Web App (PWA) for Event Management\n   • Developed a PWA using React and service workers for offline functionality\n   • Implemented push notifications and geolocation features" },
};

fileSystem['/'].contact.content = {
  'email.txt': { type: 'file', content: "Email: toddgwatts@me.com" },
  'phone.txt': { type: 'file', content: "Phone: 561-313-7056" },
  'social.txt': { type: 'file', content: "LinkedIn: linkedin.com/in/toddwatts\nGitHub: github.com/toddwatts\nTwitter: @toddwattsdev" },
};

const WELCOME_MESSAGE = "Welcome to Todd Watts' Interactive Portfolio Terminal.\n\nAs a seasoned Full Stack Developer with a decade of experience,\nI bring a unique blend of technical expertise and business acumen\nto every project.\n\nExplore my skills, projects, and experience using terminal commands.\nType 'help' to see available options and start your journey through\nmy professional landscape.\n\nLet's collaborate and build something extraordinary together!";

const processCommand = (command, currentDirectory) => {
  const parts = command.trim().split(' ');
  const cmd = parts[0].toLowerCase();

  const getAbsolutePath = (path) => {
    if (path.startsWith('/')) return path;
    const currentParts = currentDirectory.split('/').filter(Boolean);
    const newParts = path.split('/').filter(Boolean);
    const resultParts = [...currentParts];
    for (const part of newParts) {
      if (part === '..') {
        resultParts.pop();
      } else if (part !== '.') {
        resultParts.push(part);
      }
    }
    return '/' + resultParts.join('/');
  };

  const getDirectoryContent = (path) => {
    const parts = path.split('/').filter(Boolean);
    let current = fileSystem['/'];
    for (const part of parts) {
      if (current[part] && current[part].type === 'directory') {
        current = current[part].content;
      } else {
        return null;
      }
    }
    return current;
  };

  switch (cmd) {
    case 'help':
      return `Available commands:
  ls - List files and directories
  cd <directory> - Change directory
  pwd - Print working directory
  cat <file> - Display file contents
  clear - Clear the terminal screen
  fontsize <size> - Change the font size`;
    case 'ls':
      const currentDir = getDirectoryContent(currentDirectory);
      if (!currentDir) return 'Invalid directory';
      return Object.entries(currentDir)
        .map(([name, item]) => `${item.type === 'directory' ? '📁' : '📄'} ${name}`)
        .join('\n');
    case 'cd':
      if (parts.length < 2) return currentDirectory;
      const newPath = getAbsolutePath(parts[1]);
      const newDir = getDirectoryContent(newPath);
      if (newDir) {
        return newPath;
      }
      return `Directory not found: ${parts[1]}`;
    case 'pwd':
      return currentDirectory;
    case 'cat':
      if (parts.length < 2) return 'Usage: cat <file>';
      const filePath = getAbsolutePath(parts[1]);
      const fileDir = getDirectoryContent(filePath.split('/').slice(0, -1).join('/'));
      const fileName = filePath.split('/').pop();
      if (fileDir && fileDir[fileName] && fileDir[fileName].type === 'file') {
        return fileDir[fileName].content;
      }
      return `File not found: ${parts[1]}`;
    case 'clear':
      return 'CLEAR';
    case 'fontsize':
      if (parts.length < 2) return 'Usage: fontsize <size>';
      const size = parseInt(parts[1]);
      if (isNaN(size) || size < 8 || size > 24) {
        return 'Invalid font size. Please use a number between 8 and 24.';
      }
      return `FONTSIZE ${size}`;
    default:
      return `Command not found: ${cmd}. Type 'help' for available commands.`;
  }
};

const Terminal = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState([]);
  const [currentDirectory, setCurrentDirectory] = useState('/');
  const [fontSize, setFontSize] = useState(16);
  const [isMobileView, setIsMobileView] = useState(false);
  const inputRef = useRef(null);
  const terminalRef = useRef(null);
  const [version] = useState('toddwatts-v1');

  const handleClose = () => {
    window.location.href = 'about:blank';
  };

  const handleMinimize = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().then(() => {
        window.resizeTo(1024, 768);
      });
    } else if (window.innerWidth === window.screen.width && window.innerHeight === window.screen.height) {
      window.resizeTo(1024, 768);
    }
  };

  const handleMaximize = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    const focusInput = () => {
      if (!isMobileView && inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleResize = debounce(() => {
      const newIsMobileView = window.innerWidth <= 768;
      setFontSize(newIsMobileView ? 14 : 16);
      setIsMobileView(newIsMobileView);
      focusInput();
    }, 250);

    window.addEventListener('resize', handleResize);
    handleResize();
    focusInput();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileView]);

  const handleTouchStart = useCallback((e) => {
    if (e.touches.length === 1 && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const scrollToBottom = () => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    setOutput(prevOutput => [
      ...prevOutput,
      { type: 'input', text: input }
    ]);

    const response = processCommand(input, currentDirectory);
    if (response !== undefined) {
      if (response === 'CLEAR') {
        setOutput([]);
      } else if (response.startsWith('FONTSIZE')) {
        const newSize = parseInt(response.split(' ')[1]);
        setFontSize(newSize);
        setOutput(prevOutput => [
          ...prevOutput,
          { type: 'output', text: `Font size changed to ${newSize}px` }
        ]);
      } else {
        setOutput(prevOutput => [
          ...prevOutput,
          { type: 'output', text: response }
        ]);
        if (input.startsWith('cd ')) {
          setCurrentDirectory(response);
        }
      }
    }
    setInput('');
    scrollToBottom();
  };

  return isMobileView ? (
    <MobileUI
      fileSystem={fileSystem}
      currentDirectory={currentDirectory}
      setCurrentDirectory={setCurrentDirectory}
      handleCommand={(command) => {
        const response = processCommand(command, currentDirectory);
        if (response !== undefined) {
          if (command.startsWith('cd ')) {
            setCurrentDirectory(response);
          }
          return response;
        }
      }}
    />
  ) : (
    <div 
      className="terminal"
      onClick={() => inputRef.current && inputRef.current.focus()}
      onTouchStart={handleTouchStart}
      ref={terminalRef}
      style={{ fontSize: `${fontSize}px` }}
    >
      <header className="terminal-header">
        <div className="terminal-buttons">
          <button className="terminal-button close" onClick={handleClose} aria-label="Close"></button>
          <button className="terminal-button minimize" onClick={handleMinimize} aria-label="Minimize"></button>
          <button className="terminal-button maximize" onClick={handleMaximize} aria-label="Maximize"></button>
        </div>
        <a href="mailto:toddgwatts@me.com" className="contact-button">Contact Me</a>
        <nav className="social-icons">
          <a href="https://www.linkedin.com/in/gordon-todd-watts/" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="LinkedIn">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://github.com/gtwatts" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="GitHub">
            <i className="fab fa-github"></i>
          </a>
          <a href="https://x.com/wattsttaw" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="Twitter">
            <i className="fab fa-twitter"></i>
          </a>
        </nav>
      </header>
      <main className="terminal-content">
        <section className="welcome-message">
          <ReactTypingEffect
            text={WELCOME_MESSAGE}
            speed={50}
            eraseDelay={1000000}
            typingDelay={0}
          />
        </section>
        <section className="terminal-output">
          {output.map((item, index) => (
            <div key={index} className={item.type}>
              {item.type === 'input' ? '> ' : ''}{item.text}
            </div>
          ))}
        </section>
      </main>
      <footer className="terminal-footer">
        <form onSubmit={handleSubmit} className="input-line">
          <span className="prompt">{'>'}</span>
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            ref={inputRef}
            autoFocus
            aria-label="Terminal input"
          />
        </form>
        <div className="version-tag">{version}</div>
      </footer>
    </div>
  );
};

export default Terminal;
