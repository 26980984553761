import React from 'react';
import { Helmet } from 'react-helmet';
import Terminal from './components/Terminal';
import CustomCursor from './components/CustomCursor';
import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Todd Watts - Terminal Portfolio</title>
        <meta name="description" content="Todd Watts' terminal-style portfolio website showcasing skills and projects." />
        <link rel="canonical" href="https://your-website-url.com" />
      </Helmet>
      <CustomCursor />
      <Terminal />
    </div>
  );
}

export default App;
