// src/components/MobileUI.js

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './MobileUI.css';

const MobileUI = ({ fileSystem, currentDirectory, setCurrentDirectory, handleCommand }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [directoryHistory, setDirectoryHistory] = useState([]);

  const handleItemClick = useCallback(
    (name, item) => {
      if (item.type === 'directory') {
        const newPath = currentDirectory === '/' ? `/${name}` : `${currentDirectory}/${name}`;
        setDirectoryHistory((prevHistory) => [...prevHistory, currentDirectory]);
        setCurrentDirectory(newPath);
        setSelectedFile(null);
        // Execute 'cd' command to update the directory in the parent component
        handleCommand(`cd ${name}`);
      } else if (item.type === 'file') {
        setSelectedFile({ name, content: item.content });
        // Execute 'cat' command to display file contents
        handleCommand(`cat ${name}`);
      }
    },
    [currentDirectory, setCurrentDirectory, handleCommand]
  );

  const handleBack = () => {
    if (selectedFile) {
      // If a file is open, close it and return to directory content
      setSelectedFile(null);
    } else if (directoryHistory.length > 0) {
      // Navigate back to the previous directory
      const previousDirectory = directoryHistory[directoryHistory.length - 1];
      setDirectoryHistory((prevHistory) => prevHistory.slice(0, prevHistory.length - 1));
      setCurrentDirectory(previousDirectory);
      // Execute 'cd' command to update the directory in the parent component
      handleCommand(`cd ${previousDirectory}`);
    }
  };

  const renderContent = useCallback(() => {
    const getDirectoryContent = (path) => {
      const parts = path.split('/').filter(Boolean);
      let current = fileSystem['/'];
      for (const part of parts) {
        if (current[part] && current[part].type === 'directory') {
          current = current[part].content;
        } else {
          return null;
        }
      }
      return current;
    };

    const currentDir = getDirectoryContent(currentDirectory);
    if (!currentDir) {
      return <div>Directory not found</div>;
    }
    return Object.entries(currentDir).map(([name, item]) => (
      <div key={name} className="mobile-item" onClick={() => handleItemClick(name, item)}>
        <span className="mobile-item-icon">{item.type === 'directory' ? '📁' : '📄'}</span>
        <span className="mobile-item-name">{name}</span>
      </div>
    ));
  }, [currentDirectory, fileSystem, handleItemClick]);

  return (
    <div className="mobile-ui">
      <div className="mobile-header">
        <h1>Todd Watts' Interactive Portfolio</h1>
      </div>
      <div className="mobile-content">
        <div className="mobile-content-inner">
          {selectedFile ? (
            <div className="mobile-file-content">
              <h2>{selectedFile.name}</h2>
              <pre>{selectedFile.content}</pre>
            </div>
          ) : (
            <div className="mobile-directory-content">{renderContent()}</div>
          )}
          {(selectedFile || directoryHistory.length > 0) && (
            <button className="mobile-button mobile-back-button" onClick={handleBack}>
              Back
            </button>
          )}
        </div>
      </div>
      <div className="mobile-footer">
        <a href="mailto:toddgwatts@me.com" className="mobile-button">
          Contact Me
        </a>
        <div className="mobile-social-icons">
          <a
            href="https://www.linkedin.com/in/gordon-todd-watts/"
            target="_blank"
            rel="noopener noreferrer"
            className="mobile-social-icon"
            aria-label="LinkedIn"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            href="https://github.com/gtwatts"
            target="_blank"
            rel="noopener noreferrer"
            className="mobile-social-icon"
            aria-label="GitHub"
          >
            <i className="fab fa-github"></i>
          </a>
          <a
            href="https://x.com/wattsttaw"
            target="_blank"
            rel="noopener noreferrer"
            className="mobile-social-icon"
            aria-label="Twitter"
          >
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

MobileUI.propTypes = {
  fileSystem: PropTypes.object.isRequired,
  currentDirectory: PropTypes.string.isRequired,
  setCurrentDirectory: PropTypes.func.isRequired,
  handleCommand: PropTypes.func.isRequired,
};

export default MobileUI;

